// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ENVIRONMENT_URLS } from './environment-urls.const';

export const environment = {
  ...{
    sentryio: 'https://7aa46b726ced480b96dc9157f8a7389e@o309582.ingest.sentry.io/1774845',
    production: true,
    hjSiteId: 3567780,
    gtmId: 'G-8GG8MBB40B',
    isGoogleSignInEnabled: false,
    ipInfoAuthKey: '560e278a1a0c76',
    abstractApiAuthKey: 'e78c6d58520e46258d3e9b4a2947d6b6',
    googleClientId: '722280505725-gdiegqm8dche8qmfbhvs71smso7vc6e8.apps.googleusercontent.com',
    companyEnrichmentKey: '6deb315f71dc48c79205b349912aa15d',
    mailchimp: {
      subscribe: 'https://movemeback.us8.list-manage.com/subscribe/post-json?u=205ee7393087554d205cec0cd&id=0ae9dfc0fe'
    },
    domain: 'https://www.african100.com/api/v2',
    linkedIn: {
      clientId: '783puyfc5f7zfm',
      cs: 'cyDmj8FdY54JqP3U',
      scope: 'r_liteprofile%20r_emailaddress'
    },
    recaptchaSiteKey: '6LeitKAqAAAAABpgexhYBnGuLEoq4wljJNeJ9-vd'
  },
  ...{ api: ENVIRONMENT_URLS.api }
}
  /*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
